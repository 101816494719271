@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@media screen and (min-width:2560px){
   #hero{
    min-height: 70vh!important;
   }
  
  } */

  body{
    font-family: 'Rubik';
    font-feature-settings: 'liga' off;
  }
  .logo-header{
    @apply max-w-[120px] xl:max-w-[200px];
  }

  p{
    @apply font-[Cairo];
  }

  main{
    overflow-x: hidden;
  }

  @media (width >=1440) and (width < 1536){
    #drill-h1{
      font-size: 5.8rem;
    }

    #drill-h1 + p{
      font-size: 1.5rem;
    }

    :is([dir="rtl"])  #drill-h1{
      font-size: 5rem;
      line-height: 1.1;
    }

    :is([dir="rtl"]) #drill-h1 + p{
      font-size: 1.3rem;
    }
    
  }

  /* #nav.menu{
    @apply bg-[url('/src/images/nav-bg.webp')] bg-cover bg-center bg-no-repeat;
  } */

  #nav.menu{
    @apply justify-center;
    background: linear-gradient(276deg, #03A4CB 8%, #0E4C78 100%);
  }

  #nav.menu li{
    max-width: 85vw;
  }

  @media (width >=1280){
    #nav.menu li{
      max-width: 70vw;
    }
   
    body.rtl #nav.menu li{
      margin-right:12vw;
    }

    body.ltr #nav.menu li{
      margin-left:12vw;
     
    }
  }

  

  /* body.rtl #nav.menu li{
    margin-right:12vw;
  } */

  
  

  #nav.menu li a{
    @apply text-[1.4rem] xl:text-2xl text-white mb-4 pb-4 
    font-bold tracking-[0.02rem] 
    border-b rounded-none 
    ltr:pl-0 rtl:pr-0
    ltr:ml-4 rtl:mr-4
    ;
  }

  @media (width <=376px) {

    #nav.menu li a{
      @apply text-[14px];
    }

    #close-menu{top:5%;}
  
  }

  

  #nav.menu li a:hover{
    @apply outline-offset-0 outline-0 bg-transparent;
    outline-style: none;
  }

  

  #smooth-content {
    will-change: transform;
  }

  footer > *{
    @apply text-white;
  }

 .sections{
    @apply relative flex px-6 2xl:px-0 ;
 }

 .containers{
    @apply container mx-auto
 }


.faq-title{
  @apply font-bold text-xl leading-8;
}

.faq-answer{
  @apply ltr:xl:pr-[5rem] rtl:xl:pl-[5rem];
  min-height: 250px;
}
#faq-alpine .faq-answer > p{
  @apply ltr:xl:pl-12 rtl:xl:pr-12 rtl:leading-7 rtl:text-sm rtl:xl:text-base;
}

#faq-alpine button{
  @apply text-[14px] xl:text-base;
}

.bg-gradient-blue{
  @apply bg-gradient-to-br from-[#5BC8E0] to-[#0397BB];
}

.bg-highlights{
  /* background-image: url('/src/images/highlights-bg-vector.webp'),linear-gradient(to right bottom, #5bc8e0, #4bbcd7, #3aafcd, #26a3c4, #0397bb);
  background-position: center center, center center;
  background-repeat: no-repeat, no-repeat; */
  background:linear-gradient(180deg, #5BC8E0 0%, #0E4C78 100%);
}

.min-h-screen{
  min-height: 100svh;
}

.grid-document,
.grid-numbers{
  /* grid-auto-columns: 1fr; */
  /* @apply auto-cols-fr grid-cols-3 auto-rows-fr; */
  @apply gap-4 xl:gap-x-8 2xl:gap-x-8 gap-y-8 ;
  grid-auto-columns:1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows:auto auto;

}

.grid-advisors{
  @apply gap-8;
  grid-auto-columns:1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows:auto auto;
}

.number-items{
  @apply rounded-2xl border border-white border-opacity-30 backdrop-blur-xl 
  flex flex-wrap flex-col items-start p-6 bg-gradient-blue hover:bg-bondi-blue;
  
  grid-column-start:  span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
  /* flex-direction: row; */
  /* align-items:center; */
  /* justify-content: space-between; */
}

.sub-item{
  @apply grid grid-cols-3 xl:grid-cols-3 items-start place-content-between w-full;
  /* @apply flex items-center place-content-between w-full basis-[50%]; */
}

.number-items > h4{
  @apply mb-4 text-xl lg:text-2xl;
}

.sub-item > p{
  @apply col-span-2 xl:col-span-2 leading-none rtl:leading-tight;
}

.sub-item > img{
  @apply justify-self-end;
}

/* .vectorbg{
  @apply bg-no-repeat bg-fixed bg-cover;
  transform: none!important;
} */


.document-items{
  /* @apply col-start-1 row-start-1 row-end-1; */
  /* @apply rounded-3xl border min-h-[5rem] border-[#6C7789] px-6 min-w-[300px];  */
  @apply rounded-3xl border min-h-[5rem] border-white border-opacity-30 px-6 min-w-[300px] shadow backdrop-blur-[30px] ; 
  grid-column-start:  span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
  background-image: url(../src/images/noise.svg);
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items:center;
  justify-content: space-between;

}

.document-items img{
  min-width: 32px;
}

.doc-title{
  @apply text-white text-base xl:text-[1rem] 2xl:text-[1.1rem] font-medium leading-tight
  rtl:leading-tight rtl:text-base;
  
}

.advisor{
  @apply bg-[#F5F5F5] 
  flex flex-col justify-between items-start
  min-w-[200px] max-h-[236px] min-h-[236px];
  grid-column-start:  span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
}
.advisor img{
@apply self-center w-[70%] xl:w-full object-contain;
aspect-ratio: 3/2;
}
/* .advisor img{
  max-height: 83px;
}
.advisor.bank img{
  max-height: 110px;
} */

.details p{
  @apply font-medium text-sm text-dark-grey;
}
/* .details a:after{
  content: url('/src/images/external.svg');
} */

.details a{
  @apply  text-medium-blue text-base rtl:xl:text-[10px]  rtl:2xl:ml-1 rtl:3xl:text-[12px]
  /* underline */
}

.details a[href^="mailto:"]:after,
.details a[target^="_blank"]:after{
  
  content: "";
  display: inline-flex;
  width: 12px;
  height: 12px;
  background-color: #03A4CB;
  /* background:url("/src/images/arrow-right.svg"); */
  mask-image: url("/src/images/arrow-right.svg");
  @apply bg-no-repeat rtl:scale-x-[-1];

}

@media screen and (max-width: 991px)
{
  .grid-document,
  .grid-advisors,
  .grid-numbers{
    grid-template-columns: 1fr 1fr;

  }
}

@media screen and (max-width: 767px){
  .grid-document,.grid-advisors,.grid-numbers{
    grid-template-columns: 1fr;

  }
}

.slick-slide {
  margin: 0 0.5rem;

}

/* the parent */
.slick-list {
  margin: 0 -0.5rem;

}

@media (width >=768px) {
/* the slides */
.slick-slide {
  margin: 0 1.5rem;

}

/* the parent */
.slick-list {
  margin: 0 -1.5rem;

}
}

/* .slick-slider {
  overflow: hidden;
} */


.slick-left svg,.slick-right svg{
  @apply w-10 h-10 xl:w-16 xl:h-16;
}

.subtitles{
  @apply py-8 xl:py-12 items-center grid-cols-12;
}

.subtitles h3{
  @apply col-start-1 
  xl:col-start-2 col-span-8 xl:col-span-9
  /* rtl:xl:col-start-2 
  rtl:col-span-8
  rtl:xl:col-span-9 */
}

.subtitles .arrows{
  @apply col-span-3 rtl:col-span-1


}

.btn-leaders{
  @apply hover:cursor-pointer  w-16 h-16 
  xl:w-[4rem] xl:h-[4rem] 
  2xl:w-[5rem] 2xl:h-[5rem]
  rtl:left-0 rtl:right-auto rtl:scale-x-[-1];
  border-radius: 1.25rem 0rem;
}

.btn-leaders img{
  /* @apply hover:animate-spin; */

  @apply transform  transition duration-300 ease-in-out;
}

.card{
  @apply relative;
  background-position:center 30%;
}

.card-body{
  @apply  relative min-h-[360px] 2xl:min-h-[420px] px-5 
  /* xl:pb-12  */
  flex flex-wrap content-start justify-end ;
}

.overlaybg{
@apply absolute top-0 left-0 w-full h-full rounded-[1.25rem];
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 80%);
}

.info{
  @apply min-h-[55px] xl:min-h-[72px];
}

/* .modal-box{
  @apply max-w-xs xl:max-w-xl
} */

.modal-box > p:first-of-type{
  @apply pt-4;
}

.modal-box p{
  @apply pb-4 text-sm lg:text-base; 
}

.bioNames{
  @apply 
  font-bold text-base lg:text-xl 
  leading-none mb-2
  rtl:leading-tight
  rtl:max-w-[12rem]
  rtl:xl:max-w-sm
  ;
}

.bioPos{
  @apply font-medium;
}

#leadersBios > dialog > form.modal-box{
  padding:3rem 2rem 2rem 2rem;
}
#leadersBios > dialog > form.modal-box > .btn-ghost{
  @apply top-4 ltr:right-4 rtl:right-auto rtl:left-4;
}
.btn-neutral{
  @apply text-white font-normal;
}


#disclaimers > dialog.modal{
  @apply select-none;
  background-color:rgb(3, 164, 203,0.5)
}

#disclaimers > dialog > .modal-box{
  @apply max-h-[80vh] xl:max-h-[calc(100vh-5em)];
}


#disclaimers > dialog > .modal-box h1{
@apply text-bondi-blue text-xl font-medium xl:text-2xl uppercase leading-none mb-4;
}
#disclaimers > dialog > .modal-box h3{
  @apply text-black rtl:text-[1.3rem]  ;
}

.agree-btn-yes{
  @apply ltr:mr-4 rtl:ml-4;
}


#disclaimers > dialog > .modal-box p,
#disclaimers > dialog > .modal-box ul li{
  @apply font-[Cairo] text-black text-sm xl:text-base;
}

#disclaimers .select:focus {
  outline-offset: 0;
  outline-width: 0;
}

#disclaimers .btn{
  border-radius: 1.25rem;
  min-width: 8rem;
}

#disclaimers .ivh{
  @apply px-5;
}
#disclaimers .ivh li{
  padding-right: 0;
}

/* #disclaimers > dialog > .modal-box span.btn{
  @apply text-white;
} */




/* .bg-hero{

    width: 100%;
    min-height: 100vh;
    object-fit:contain;
    background: url('/src/images/hero_bg.webp');
    position: absolute;
    top: 0;
    z-index: -1;
     background-repeat:no-repeat;
    background-size:cover;

} */

.hero-bg{
  background-color:rgba(0,0,0,0.45);

}
.parallax{

  @apply hero-bg lg:bg-transparent bg-blend-multiply;
  
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
/* cards */
.cards{
  width: 100%;
}

/* .cards .card-item.faded > * {
  @apply opacity-90;
} */



.cards .card-item {
  @apply bg-[#217396] transition-colors rounded-[1.25rem] p-8
  mb-8
  flex flex-wrap flex-col justify-between items-baseline
  bg-[url('../src/images/card-noise.png')] bg-cover bg-no-repeat 
  min-h-[280px] xl:min-h-[390px];
  
  width: 100%;
  
}




.cards .card-item:first-child {
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.05);
}

.cards .card-item > h1 {
  @apply text-ades-white text-3xl 2xl:text-[6rem] leading-none
  rtl:flex rtl:self-start rtl:items-end;
  ;
}

.cards .card-item > p{
  @apply leading-none text-base xl:text-[1.3rem] text-ades-white font-[Rubik] 
  rtl:leading-tight rtl:text-right
  rtl:self-start;
}

/* .cards .card-item-last{
  @apply bg-none;
} */


/* faqs */

.faq-item{
	border-radius: 1.25rem;
border: 1px solid rgba(255, 255, 255, 0.32);
/* background: linear-gradient(180deg, rgba(3, 164, 203, 0.30) 0%, rgba(3, 164, 203, 0.00) 100%); */
/* background-color: #0397BB; */
backdrop-filter: blur(15px);
}

/* .faq-item > button + div{
  min-height: 300px;
} */

#faq-alpine  p{
  @apply font-[Cairo] px-8;
}

/* end faqs */

.lang-select > a{
  @apply text-[11px] lg:text-base;
}

/* nav gsap*/
.navbar {
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.site-logo {
  font-size: 18px;
  color: #000;
}


.name{
  @apply text-ades-white font-medium text-[1.1rem]
  2xl:text-[1.3rem] leading-none tracking-tight mb-2 font-[Rubik]
  rtl:leading-tight
  rtl:max-w-[12rem] rtl:xl:max-w-[15rem]
  ;
}
.position{
  @apply text-light-blue leading-none text-sm font-[Rubik]
  rtl:text-base
  rtl:leading-tight
  rtl:max-w-[12rem] 
  ;
}

.slick-arrow > svg{
  @apply  fill-dark-grey stroke-dark-grey;
}

.slick-arrow.slick-disabled > svg{
@apply  fill-none;
}

/* .gs_reveal{
  @apply opacity-0 invisible;
  will-change: transform, opacity;
} */

.gs_reveal {
  /* opacity: 0;
  visibility: hidden; */
  will-change: transform, opacity;
}

/* timeline panels */

#scroll-timeline{
  @apply -mt-[10px] rtl:mt-4 xl:-mt-8 rtl:xl:-mt-1
}

.panel {
  @apply flex flex-wrap p-8 justify-between shadow-2xl bg-opacity-50 backdrop-blur-xl;
  min-width: 320px;
  min-height: 325px;
  aspect-ratio:  0.97 / 1;
  
}

h3.day{
  @apply font-bold text-2xl xl:text-3xl leading-none
}

h3.month{
  @apply font-normal text-xl xl:text-2xl leading-none
}

.panel-description{
  @apply xl:min-h-[72px];
}

.panel-description p{
  @apply leading-6 font-normal text-base xl:text-[1.25rem] font-[Cairo];

}

.panel-icon{
  @apply self-end;
}

#howto.grid{
  @apply py-16 pt-24 xl:py-24 xl:pt-32
}

/* nav */
.nav-container {

  transition: all 0.3s;
  visibility: hidden;
}

#menu-toggle-btn {
  transition: all 0.3s;
  z-index: 100000;
  cursor: pointer;
}


span.span-menu {
  height: 2px;
  background: #000;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  transition: all 0.3s;
}

span.span-menu::before {
  content: " ";
  position: absolute;
  display: inline-block;
  height: 2px;
  background: #000;
  transform: translateY(-4px);
  width: 24px;
  transition: all 0.3s;
  top: -2px;
}

span.span-menu::before {
  content: " ";
  position: absolute;
  display: inline-block;
  height: 2px;
  background: #000;
  transform: translateY(-4px);
  width: 24px;
  transition: all 0.3s;
  top: -2px;
}

.active span.span-menu {
  transform: rotate(45deg);
  background: #fff;
}

.active span.span-menu::before {
  top: unset;
  transform: rotate(-90deg);
  background: #fff;
}

.active:hover span.span-menu::before {
  top: unset;
}

.nav-item-wrapper::after {
  content: "";
  position: absolute;
  top: 60px;
  left: 0;
  width: 500px;
  height: 60px;
  background: transparent;
  margin: 0 auto;
  transition: 1s;
}

/* end nav gsap */


#ipo{
  /* @apply bg-[linear-gradient(to_right_bottom,rgba(91,200,224,0.5),rgb(3,151,187,0.5)),url('/src/images/bg_vector_ipo.webp')] bg-cover bg-center; */

  /* bg-gradient-to-br from-[#0397BB] to-[#0397BB] */

  /* 
  bg-gradient-to-br from-[#5BC8E0] to-[#0397BB]
  bg-[url('/src/images/bg_vector_ipo.webp')]
  */
  
  /* @apply bg-cover bg-center;

  background-image:url('/src/images/bg_vector_ipo.webp'),linear-gradient(180deg, #5BC8E0 0%, #0397BB 100%); */

  background-image: linear-gradient(180deg, #5BC8E0 0%, #0397BB 100%);

}


.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

 /* test */

 svg {
    overflow: visible !important;
  }
  
  .parallax-section {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    top: 0;
    padding: 0 1rem;
    transform-origin: center top;
  }
  
  .parallax-image {
    height: 200vh;
    width: 100%;
    object-fit: cover;
    will-change: transform;  
  }
  
  .logo-section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;  
  }
  
  .logo-section svg {
    width: 60%;
  }
  
  .grid-section {
    width: 100%;
    overflow: visible;
    z-index: 2;
  }
  
  .grid-layout {
    display: flex;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    transform-origin: center top;
  }
  
  .column {
    height: 100%;
  }
  
  /* .panel {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  .column-content {
    display: grid;
    grid-template-rows: 45vh 45vh 45vh;
    grid-gap: 1rem;
    height: 100%;
  }
  
  .grid-image {
    width: 100%;  
  }
  
  .grid-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .pin-section {
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-row-gap: 1rem;
    grid-template-rows: 1fr 1fr;
  }
  
  .pin-content {
    width: 300%;  
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    will-change: transform;
  }
  
  .pin-box {
    height: 100%;
    width: 100%;
  }
  
  .pin-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .spacer {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scroll-letter {
    fill: #fff;
  }
  
  .smoother-letter,
  #mouse-wheel {
    fill: #88ce02;
  }
  
  .by-greensock-letter {
    fill: #fff;
    opacity: 0.6;
  }
  
  #mouse-outline {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 13px;
  }
  

 /* end test */


/* accordion */

.ivh{
  @apply list-disc list-outside lg:columns-2 px-8 lg:px-12 font-[Cairo];
}

.ivh-1{
  @apply lg:columns-1;
}
.ivh li{
  @apply ltr:lg:pr-8 rtl:lg:pl-8 mb-3;
}

.f-header{
  @apply flex flex-col xl:flex-row items-start xl:items-center max-w-[280px] md:max-w-xl lg:max-w-3xl xl:max-w-4xl;
}

.f-number{
  @apply 
    ltr:mr-2 rtl:ml-2
    ltr:lg:mr-4 ltr:lg:ml-2 
    rtl:lg:ml-4 rtl:lg:mr-2 
    xl:ml-[18px] xl:mr-8
    rtl:xl:mr-[18px] rtl:xl:ml-8
   text-light-blue font-bold 
   text-[2.2rem] xl:text-[2.4rem] 2xl:text-[2.5rem];
}
.f-title{
  @apply xl:font-medium ltr:xl:pr-12 
  text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.25rem] 
  ltr:leading-none rtl:leading-tight;
}


/* 
 .accordions {
  padding-bottom: 200px;
}

.accordion__btn {
  cursor: pointer;
  color: inherit;
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px;
  text-align: left;
}
.accordion__btn img {
  max-width: 10px;
}
.accordion__content {
  color: inherit;
  overflow: hidden;
  height: 0px;
  padding-bottom: 0;
  font-size: 18px;
  opacity: 0;
  will-change: opacity, transform;
  visibility: hidden;
}
.accordion.active .accordion__content {
  padding-bottom: 30px;
}
.accordion__content ul li {
  padding-left: 20px;
  background-image: var(--ellipse-open-bg);
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 18px;
}
.accordion__content ul li:not(:last-of-type) {
  margin-bottom: 6px;
}
.accordion__content ul li.on {
  background-image: var(--ellipse-bg);
  font-family: var(--font-bold);
} */

/* accordion */


h2 > button{
  border-radius: 1.25rem;
    border: 1px rgba(255,255,255,0.32) solid;
    backdrop-filter: blur(40px);
}

/* videojs */

/* .video-js .vjs-tech {
  position: relative;
  height: inherit;
}

#videoPlayer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: black;
  height:auto
} */